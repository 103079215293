export const ConfigDataSidebarData = [
  {
    title: "SUPPORT NUMBER",
    link: "supportNumber",
  },
  {
    title: "SUPPORT EMAIL",
    link: "supportEmail",
  },
  {
    title: "ADMIN EMAIL",
    link: "adminEmail",
  },
  {
    title: "ADDITIONAL DISCOUNT",
    link: "additionalDiscount",
  },
  {
    title: "INVOICE RECEIVED EMAIL",
    link: "invoiceReceivedEmail",
  },
  {
    title: "LIVE VIDEO SETTING",
    link: "liveVideoSetting",
  },
  {
    title: "TAX SETTING",
    link: "taxSetting",
  },
  {
    title: "INSTALLMENT SETTING",
    link: "installmentSetting",
  },
  {
    title: "ISSUE TYPE",
    subNav: [
      {
        title: "ADD ISSUE",
        path: "addIssueType",
        cName: "sub-nav",
      },
      {
        title: "EDIT / DELETE",
        path: "editDeleteIssueType",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "SHIPPING CHARGE",
    subNav: [
      {
        title: "ADD CHARGES",
        path: "addCharges",
        cName: "sub-nav",
      },
      {
        title: "EDIT / DELETE",
        path: "editDeleteCharges",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "QUESTION CONFIG",
    subNav: [
      {
        title: "ADD",
        path: "addQuestionConfig",
        cName: "sub-nav",
      },
      {
        title: "EDIT / DELETE",
        path: "editDeleteQuestionConfig",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "APP VERSION CONTROL",
    link: "appVersionControl",
  },
  {
    title: "OTP SETTING",
    link: "otpSetting",
  },
  {
    title: "SERVERS",
    link: "serversList",
  },
  {
    title: "QUESTION ISSUE",
    subNav: [
      {
        title: "ADD",
        path: "addQuestionIssue",
        cName: "sub-nav",
      },
      {
        title: "EDIT / DELETE",
        path: "editQuestionIssue",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "WHATSAPP MESSAGE",
    link: "whatsappMsg",
  },
  {
    title: "LEAD ORDERS SEARCH",
    link: "leadOrdersSearch",
  },
  {
    title: "WHATSAPP SHOOT DATA",
    link: "whatsappShotData",
  },
  {
    title: "ADD BBB PDF AND VIDEO",
    link: "addBBBPDFandVideo",
  },
];
