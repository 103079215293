export const InfraPanelSidebarData = [
  {
    title: "RAISE ISSUE",
    subNav: [
      {
        title: "Create",
        path: "createissue",
        cName: "sub-nav",
      },
      {
        title: "ISSUE LIST",
        path: "editdeleteissue",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "INFRA SUPPORT",
    link: "infraissue",
  },
];
