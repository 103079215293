export const AdminPanelSidebarData = [
  {
    title: "EXAM NAME",
    subNav: [
      {
        title: "CREATE",
        path: "createexam",
        cName: "sub-nav",
      },
      {
        title: "EDIT / DELETE",
        path: "editdeleteexam",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "SUB EXAM NAME",
    subNav: [
      {
        title: "CREATE",
        path: "createsubexam",
        cName: "sub-nav",
      },
      {
        title: "EDIT / DELETE",
        path: "editdeletesubexam",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "EXAM STREAM",
    subNav: [
      {
        title: "CREATE",
        path: "createparentcourse",
        cName: "sub-nav",
      },
      {
        title: "EDIT / DELETE",
        path: "editdeleteparentcourse",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "LANGUAGE",
    subNav: [
      {
        title: "CREATE",
        path: "createlanguage",
        cName: "sub-nav",
      },
      {
        title: "EDIT / DELETE",
        path: "editdeletelanguage",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "ASSIGN EXAM STREAM",
    link: "createassignexamstream",
  },
  {
    title: "SUB CATEGORY",
    subNav: [
      {
        title: "CREATE",
        path: "createsubcategory",
        cName: "sub-nav",
      },
      {
        title: "EDIT / DELETE",
        path: "editdeletesubcategory",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "SUBJECT",
    subNav: [
      {
        title: "CREATE",
        path: "createsubject",
        cName: "sub-nav",
      },
      {
        title: "EDIT / DELETE",
        path: "editdeletesubject",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "MAIN CATEGORY",
    subNav: [
      {
        title: "CREATE",
        path: "createmaincategory",
        cName: "sub-nav",
      },
      {
        title: "EDIT / DELETE",
        path: "editdeletemaincategory",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "SECTION",
    subNav: [
      {
        title: "CREATE",
        path: "createsection",
        cName: "sub-nav",
      },
      {
        title: "EDIT / DELETE",
        path: "editdeletesection",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "ASSIGN BOOKS",
    subNav: [
      {
        title: "CREATE",
        path: "createassignbooks",
        cName: "sub-nav",
      },
      {
        title: "EDIT / DELETE",
        path: "editdeleteassignbooks",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "PACKAGE",
    subNav: [
      {
        title: "CREATE",
        path: "createpackage",
        cName: "sub-nav",
      },
      {
        title: "EDIT / DELETE",
        path: "editdeletepackage",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "PACKAGE EXTEND",
    subNav: [
      {
        title: "CREATE",
        path: "createpackageextend",
        cName: "sub-nav",
      },
      {
        title: "EDIT / DELETE",
        path: "editdeletepackageextend",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "TEACHER",
    subNav: [
      {
        title: "CREATE",
        path: "createteacher",
        cName: "sub-nav",
      },
      {
        title: "EDIT / DELETE",
        path: "editdeleteteacher",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "ASSIGN UNITS",
    subNav: [
      {
        title: "CREATE",
        path: "createassignunits",
        cName: "sub-nav",
      },
      {
        title: "EDIT / DELETE",
        path: "editdeleteassignunit",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "AD BANNER TYPE",
    subNav: [
      {
        title: "CREATE",
        path: "createadbanertype",
        cName: "sub-nav",
      },
      {
        title: "EDIT / DELETE",
        path: "editdeleteadbanertype",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "PATTERN",
    subNav: [
      {
        title: "CREATE",
        path: "createpattern",
        cName: "sub-nav",
      },
      {
        title: "EDIT / DELETE",
        path: "editdeletepattern",
        cName: "sub-nav",
      },
      {
        title: "BIND PATTERNS",
        path: "bindpatterns",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "ADMIT CARD",
    subNav: [
      {
        title: "CREATE",
        path: "createadmitcard",
        cName: "sub-nav",
      },
      {
        title: "EDIT / DELETE",
        path: "editdeleteadmitcard",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "EDUTECH COUPON CODE",
    subNav: [
      {
        title: "CREATE",
        path: "createcouponcode",
        cName: "sub-nav",
      },
      {
        title: "CREATE MULTIPLE EXAM",
        path: "createcouponcodemultipleexam",
        cName: "sub-nav",
      },
      {
        title: "EDIT / DELETE",
        path: "editdeletecouponcode",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "EDUTECH COUNSELLOR EXAM COUPON",
    subNav: [
      {
        title: "CREATE ALL COUNSELLOR EXAM COUPONS",
        path: "createcouponcodeexam",
        cName: "sub-nav",
      },
      {
        title: "CREATE SINGLE COUNSELLOR EXAM COUPONS",
        path: "createCouponCodeNew",
        cName: "sub-nav",
      },
      {
        title: "EDIT / DELETE",
        path: "editdeletecouponcodeEdutech",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "EDUTECH ALL COURSES COUNSELLOR COUPON & EXTENSION",
    subNav: [
      {
        title: "CREATE",
        path: "createCounsellorCouponNew",
        cName: "sub-nav",
      },
      {
        title: "EDIT / DELETE",
        path: "editDeleteCounsellorCouponNew",
        cName: "sub-nav",
      },
      {
        title: "COPY COUNSELLOR COUPON",
        path: "copyCounsellorCoupon",
        cName: "sub-nav",
      },
    ],
  },
  // {
  //   title: "PUBLICATION COUPON CODE",
  //   subNav: [
  //     {
  //       title: "CREATE",
  //       path: "createPublicationCouponCode",
  //       cName: "sub-nav",
  //     },
  //     {
  //       title: "EDIT / DELETE",
  //       path: "editDeletePublicationCouponCode",
  //       cName: "sub-nav",
  //     },
  //   ],
  // },
  {
    title: "BB MEETING ID",
    subNav: [
      {
        title: "CREATE",
        path: "createbbmeetingid",
        cName: "sub-nav",
      },
      {
        title: "EDIT / DELETE",
        path: "editdeletebbmeetingid",
        cName: "sub-nav",
      },
      {
        title: "BIND MEETING IDS",
        path: "bindbbbmeeting",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "BRANCH",
    subNav: [
      {
        title: "CREATE",
        path: "createbranch",
        cName: "sub-nav",
      },
      {
        title: "EDIT / DELETE",
        path: "editdeletebranch",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "BATCH TYPE",
    subNav: [
      {
        title: "CREATE",
        path: "createbatchtype",
        cName: "sub-nav",
      },
      {
        title: "EDIT / DELETE",
        path: "editdeletebatchtype",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "COURSE TYPE",
    subNav: [
      {
        title: "CREATE",
        path: "createcoursetype",
        cName: "sub-nav",
      },
      {
        title: "EDIT / DELETE",
        path: "editdeletecoursetype",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "SOP / PPT",
    subNav: [
      {
        title: "CREATE",
        path: "createSopPpt",
        cName: "sub-nav",
      },
      {
        title: "EDIT / DELETE",
        path: "editDeletSopPpt",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "REGISTRATION DETAILS",
    link: "registrationdetails",
  },
  {
    title: "SERVERS",
    link: "serversList",
  },
  {
    title: "DOUBT SOLVE REVIEW",
    link: "doubtsolvereview",
  },

  {
    title:"BASE OFFER DISCOUNT",
    link:"baseOfferDiscount"
  },
  {
    title: "WEB FREE YT LECTURE",
    subNav: [
      {
        title: "ADD",
        path: "addFreeYTLectures",
        cName: "sub-nav",
      },
      {
        title: "EDIT / DELETE",
        path: "editFreeYTLectures",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "CATEGORY INCLUDE DESCRIPTION",
    link: "addNewCatDesc",
  },
  {
    title: "COPY CATEGORY INCLUDE",
    subNav: [
      {
        title: "COPY",
        path: "copyCategoryInclude",
        cName: "sub-nav",
      },
      {
        title: "EDIT/DELETE",
        path: "editDeleteCategoryInclude",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "ADD RECORDED LECTURE IN COURSE",
    link: "addRecordedLectureInCourse",
  },
  {
    title: "QUESTION DOUBT",
    link: "questionDoubt",
  },
  {
    title: "EXTEND GLOBAL",
    subNav: [
      {
        title: "ADD",
        path: "createExtendGlobal",
        cName: "sub-nav",
      },
      {
        title: "EDIT / DELETE",
        path: "editDeleteExtendGlobal",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "UPGRADE GLOBAL",
    subNav: [
      {
        title: "ADD",
        path: "createUpgradeGlobal",
        cName: "sub-nav",
      },
      {
        title: "EDIT / DELETE",
        path: "editDeleteUpgradeGlobal",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "EXAM MAPPING",
    subNav: [
      {
        title: "ADD",
        path: "addExamMapping",
        cName: "sub-nav",
      },
      {
        title: "EDIT / DELETE",
        path: "editExamMapping",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "DEPARTMENT MAPPING",
    subNav: [
      {
        title: "ADD",
        path: "addDepartmentMapping",
        cName: "sub-nav",
      },
      {
        title: "EDIT / DELETE",
        path: "editDepartmentMapping",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "ADD ON MAPPING",
    link: "addOnMapping",
  },
  {
    title: "WHATSAPP MESSAGE",
    subNav: [
      {
        title: "ADD REGISTRATION CONFIGURATION",
        path: "studentCouponAdd",
        cName: "sub-nav",
      },
      {
        title: "EDIT / DELETE",
        path: "studentCouponEdit",
        cName: "sub-nav",
      },
    ],
  },
];
