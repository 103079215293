export const CategoryPanelManagerSidebarData = [
  {
    title: "LEAD DASHBOARD",
    subNav: [
      {
        title: "LEAD DASHBOARD",
        path: "leadDashboard",
        cName: "sub-nav",
      },
      {
        title: "LEAD COUNT",
        path: "LeadCount",
        cName: "sub-nav",
      },
      {
        title: "LEAD ANALYSIS",
        path: "leadAnalysis",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "TARGET DASHBOARD",
    subNav: [
      {
        title: "CREATE TARGET",
        path: "createTarget",
        cName: "sub-nav",
      },
      {
        title: "TARGET STATUS",
        path: "targetStatus",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "TEACHER PERFORMANCE",
    link: "teacherPerformanceCat",
  },
  {
    title: "ADMISSION ANALYSIS",
    subNav: [
      {
        title: "BATCHWISE COUNT",
        path: "BatchwiseCount",
        cName: "sub-nav",
      },
      {
        title: "DATEWISE COUNT",
        path: "DatewiseCount",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "YOUTUBE DASHBOARD",
    subNav: [
      {
        title: "ADD YOUTUBE DASHBOARD",
        path: "addYoutubeData",
        cName: "sub-nav",
      },
      {
        title: "EDIT / DELETE",
        path: "editYoutubeData",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "EXAM DETAILS DASHBOARD",
    subNav: [
      {
        title: "EXAM SYLLABUS",
        subNav: [
          {
            title: "CREATE",
            path: "createExamSyllabus",
            cName: "sub-nav",
          },
          {
            title: "EDIT / DELETE",
            path: "editDeleteExamSyllabus",
            cName: "sub-nav",
          },
        ],
      },
      {
        title: "ALL ABOUT EXAM",
        path: "allAboutExam",
        cName: "sub-nav",
      },

      {
        title: "EXAM DATES",
        path: "createExamDates",
        cName: "sub-nav",
      },
      {
        title: "PYQ PDF",
        subNav: [
          {
            title: "CREATE",
            path: "createpyqpdf",
            cName: "sub-nav",
          },
          {
            title: "EDIT / DELETE",
            path: "editdeletepyqpdf",
            cName: "sub-nav",
          },
        ],
      },
      {
        title: "EXAM PAPER ANALYSIS",
        path: "createExamPaperAnalysis",
        cName: "sub-nav",
      },
      {
        title: "CUT-OFF",
        path: "cutOff",
        cName: "sub-nav",
      },
      {
        title: "PATTERN",
        subNav: [
          {
            title: "CREATE",
            path: "createpattern",
            cName: "sub-nav",
          },
          {
            title: "EDIT / DELETE",
            path: "editdeletepattern",
            cName: "sub-nav",
          },
        ],
      },
    ],
  },
  {
    title: "RESULT DASHBOARD",
    subNav: [
      {
        title: "EXAM RESULTS",
        subNav: [
          {
            title: "ADD RESULTS",
            path: "addResults",
            cName: "sub-nav",
          },
          {
            title: "EDIT / DELETE",
            path: "editDeleteResults",
            cName: "sub-nav",
          },
          {
            title: "COPY EXAM RESULT",
            path: "copyResultsList",
            cName: "sub-nav",
          },
        ],
      },
      {
        title: "NEW EXAM RESULTS",
        subNav: [
          {
            title: "ADD RESULTS",
            path: "addExamResults",
            cName: "sub-nav",
          },
          {
            title: "EDIT / DELETE",
            path: "editDeleteExamResults",
            cName: "sub-nav",
          },
        ],
      },
      {
        title: "SCHOLARSHIP EXAM RESULTS",
        path: "ScholarshipExamResult",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "TEACHER DASHBOARD",
    subNav: [
      {
        title: "TEACHER TASK PLAN",
        path: "teacherTaskPlan",
        cName: "sub-nav",
      },
      {
        title: "TEACHER TASK PLAN COMPLETE",
        path: "teacherTaskPlanComplete",
        cName: "sub-nav",
      },
      {
        title: "TEACHER TASK PLAN PENDING",
        path: "teacherTaskPlanPending",
        cName: "sub-nav",
      },
      {
        title: "TEACHER TASK PLAN NOT COMPLETED",
        path: "teacherTaskPlanNotCompleted",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "AD BANER",
    subNav: [
      {
        title: "ADD BANER",
        path: "addadbaner",
        cName: "sub-nav",
      },
      {
        title: "EDIT / DELETE",
        path: "editdeleteadbaner",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "MANAGER EMPLOYEE",
    subNav: [
      {
        title: "CREATE",
        path: "addAcademicHeadEmployee",
        cName: "sub-nav",
      },
      {
        title: "EDIT / DELETE",
        path: "editDeleteAcademicHeadEmployee",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "TEACHER TASK PLAN",
    subNav: [
      {
        title: "ADD TEACHER TASK",
        path: "addTeacherTaskCategory",
        cName: "sub-nav",
      },
      {
        title: "EDIT / DELETE",
        path: "editTeacherTaskCategory",
        cName: "sub-nav",
      },
    ],
  },
];

export const CategoryPanelSidebarData = [
  {
    title: "LEAD DASHBOARD",
    subNav: [
      {
        title: "LEAD DASHBOARD",
        path: "leadDashboard",
        cName: "sub-nav",
      },
      {
        title: "LEAD COUNT",
        path: "LeadCount",
        cName: "sub-nav",
      },
      {
        title: "LEAD ANALYSIS",
        path: "leadAnalysis",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "TARGET STATUS",
    link: "targetStatus",
  },
  {
    title: "TEACHER PERFORMANCE",
    link: "teacherPerformanceCat",
  },
  {
    title: "ADMISSION ANALYSIS",
    subNav: [
      {
        title: "BATCHWISE COUNT",
        path: "BatchwiseCount",
        cName: "sub-nav",
      },
      {
        title: "DATEWISE COUNT",
        path: "DatewiseCount",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "YOUTUBE DASHBOARD",
    subNav: [
      {
        title: "ADD YOUTUBE DASHBOARD",
        path: "addYoutubeData",
        cName: "sub-nav",
      },
      {
        title: "EDIT / DELETE",
        path: "editYoutubeData",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "EXAM DETAILS DASHBOARD",
    subNav: [
      {
        title: "EXAM SYLLABUS",
        subNav: [
          {
            title: "CREATE",
            path: "createExamSyllabus",
            cName: "sub-nav",
          },
          {
            title: "EDIT / DELETE",
            path: "editDeleteExamSyllabus",
            cName: "sub-nav",
          },
        ],
      },
      {
        title: "ALL ABOUT EXAM",
        path: "allAboutExam",
        cName: "sub-nav",
      },

      {
        title: "EXAM DATES",
        path: "createExamDates",
        cName: "sub-nav",
      },
      {
        title: "PYQ PDF",
        subNav: [
          {
            title: "CREATE",
            path: "createpyqpdf",
            cName: "sub-nav",
          },
          {
            title: "EDIT / DELETE",
            path: "editdeletepyqpdf",
            cName: "sub-nav",
          },
        ],
      },
      {
        title: "EXAM PAPER ANALYSIS",
        path: "createExamPaperAnalysis",
        cName: "sub-nav",
      },
      {
        title: "CUT-OFF",
        path: "cutOff",
        cName: "sub-nav",
      },
      {
        title: "PATTERN",
        subNav: [
          {
            title: "CREATE",
            path: "createpattern",
            cName: "sub-nav",
          },
          {
            title: "EDIT / DELETE",
            path: "editdeletepattern",
            cName: "sub-nav",
          },
        ],
      },
    ],
  },
  {
    title: "RESULT DASHBOARD",
    subNav: [
      {
        title: "EXAM RESULTS",
        subNav: [
          {
            title: "ADD RESULTS",
            path: "addResults",
            cName: "sub-nav",
          },
          {
            title: "EDIT / DELETE",
            path: "editDeleteResults",
            cName: "sub-nav",
          },
          {
            title: "COPY EXAM RESULT",
            path: "copyResultsList",
            cName: "sub-nav",
          },
        ],
      },
      {
        title: "NEW EXAM RESULTS",
        subNav: [
          {
            title: "ADD RESULTS",
            path: "addExamResults",
            cName: "sub-nav",
          },
          {
            title: "EDIT / DELETE",
            path: "editDeleteExamResults",
            cName: "sub-nav",
          },
        ],
      },
      {
        title: "SCHOLARSHIP EXAM RESULTS",
        path: "ScholarshipExamResult",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "TEACHER DASHBOARD",
    subNav: [
      {
        title: "TEACHER TASK PLAN",
        path: "teacherTaskPlan",
        cName: "sub-nav",
      },
      {
        title: "TEACHER TASK PLAN COMPLETE",
        path: "teacherTaskPlanComplete",
        cName: "sub-nav",
      },
      {
        title: "TEACHER TASK PLAN PENDING",
        path: "teacherTaskPlanPending",
        cName: "sub-nav",
      },
      {
        title: "TEACHER TASK PLAN NOT COMPLETED",
        path: "teacherTaskPlanNotCompleted",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "AD BANER",
    subNav: [
      {
        title: "ADD BANER",
        path: "addadbaner",
        cName: "sub-nav",
      },
      {
        title: "EDIT / DELETE",
        path: "editdeleteadbaner",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "MANAGER EMPLOYEE",
    subNav: [
      {
        title: "CREATE",
        path: "addAcademicHeadEmployee",
        cName: "sub-nav",
      },
      {
        title: "EDIT / DELETE",
        path: "editDeleteAcademicHeadEmployee",
        cName: "sub-nav",
      },
    ],
  },

  {
    title: "RESULT COMPILATION",
    link: "resultCompilation",
  },
  {
    title: "STUDENT RESULT",
    link: "studentResult",
  },
  {
    title: "PRIME CLASS",
    subNav: [
      {
        title: "ADD VIDEO",
        path: "addFreeVideo",
        cName: "sub-nav",
      },
      {
        title: "EDIT / DELETE",
        path: "editFreeVideo",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "TEACHER TASK PLAN",
    subNav: [
      {
        title: "ADD TEACHER TASK",
        path: "addTeacherTaskCategory",
        cName: "sub-nav",
      },
      {
        title: "EDIT / DELETE",
        path: "editTeacherTaskCategory",
        cName: "sub-nav",
      },
    ],
  },
];
